import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteTelegramData, deleteTelegramRemoveStateData } from 'features/telegram/deleteTelegramSlice';
import { getTelegramTableData } from 'features/telegram/telegramTableSlice';
import React, { useRef } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import AddTelegramId from './components/AllModals/AddTelegramId';

const TelegramId = () => {
  const [alert, setAlert] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const tableData = useSelector((state) => state.telegramTable);
  const dispatch = useDispatch();
  const refScrollUp = useRef(null);


  const telegramDelete = useSelector((state) => state.deleteTelegram);

  const handleDeleteTelegram = async (TelegramId) => {
    // API call
    hideAlert();
    await dispatch(await deleteTelegramData({ ...TelegramId }));
    await dispatch(await getTelegramTableData());
  }

  React.useEffect(() => {
    dispatch(getTelegramTableData());
  }, []);

  React.useEffect(() => {
    if (telegramDelete.loading === "succeeded") {
      successDelete();
      dispatch(deleteTelegramRemoveStateData());
      setLoading(false);
    }
    if (telegramDelete.loading === "failed") {
      hideAlert();
      notify(telegramDelete?.error?.data?.message, true);
      dispatch(deleteTelegramRemoveStateData());
      setLoading(false);
    }
    if (telegramDelete.loading === "loading") {
      setLoading(true);
    }
  }, [telegramDelete.loading]);

  React.useEffect(() => {

    if (tableData.loading === "loading") {
      setLoading(true);
    }
    if (tableData.loading === "failed") {
      notify(tableData?.error?.data?.message, true);
      setLoading(false);
    }
    window.scrollTo(0, 0);

  }, [tableData.loading]);

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        TelegramId hasa been deleted.
      </ReactBSAlert>
    );
  };


  const warningWithConfirmMessage = (TelegramId) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteTelegram(TelegramId)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this TelegramId!
      </ReactBSAlert>
    );
  };

  const notify = (msg, isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError ? "danger" : "success",
      icon: isError ? "tim-icons icon-alert-circle-exc" : "tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const hideAlert = () => {
    setAlert(null);
  };

  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);

  const handleOpenAddModal = async () => {
    if( tableData?.page_Data.length >= 5){
      notify("You can't add more than 5 Telegram Ids", true);
      return;
    }
    toggle();
  }

  return (
    <>
      { }
      <div className='content ' id='telegramTable' >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Card ref={refScrollUp}>
          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
              <div className="numbers">
                <p className=" px-2 mb-0 h4 text-white text-capitalize">existing Telegram Ids</p>
              </div>
              <Button className="btn-simple border-white text-white d-flex add-btn justify-content-center align-items-center text-capitalize" color="primary" type="button" onClick={handleOpenAddModal}>
                <i className="tim-icons icon-simple-add pr-2" />Add New Telegram Id
              </Button>

            </div>
          </CardHeader>
          <CardBody>
            {
              tableData?.page_Data?.map((item, index) =>
              (
                <>
                  <Card className="facility-card" key={item?.telegram_name + index + "Card"}>
                    <CardHeader>
                      <Row>
                        <Col sm={10}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-fit">
                              <p className=" px-2 mb-0 text-white fs-2 font-weight-normal">Telegram Id</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event' >
                              <span className="px-2 mb-0 text-white-50 fs-2 font-weight-light ">{item?.telegram_id}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={2}>
                          <div className="d-flex justify-content-around align-items-center py-3" >
                            <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={() => warningWithConfirmMessage(item)} />
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </>
              )
              )
            }

            <>
              {(tableData.page_Data && tableData.page_Data.length > 0) ?
                <></>
                : <p className='h5'>There no existing Telegram Id here, press "Add new Telegram Id" to create first one.</p>
              }
            </>
          </CardBody>
        </Card>
      </div>
      {open ?
        <AddTelegramId
          open={open}
          toggle={toggle}
          notify={notify}
        /> : <></>
      }
      {(telegramDelete.loading === "loading" || tableData?.loading === "loading") &&
        <div className="loading-container">
          <div className="d-flex justify-content-center loading-div"><Spinner size="xl" /></div>
        </div>
      }
    </>
  )
}

export default TelegramId

