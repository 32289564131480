import React from 'react';
import * as Yup from 'yup';
import { addTelegramData, addTelegramRemoveStateData } from 'features/telegram/addTelegramSlice';
import { getTelegramTableData } from 'features/telegram/telegramTableSlice';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';

const AddTelegramId = ({ open, toggle, notify }) => {
    const addTelegram = useSelector((state) => state.addTelegram);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (addTelegram.loading === 'succeeded') {
            toggle();
            dispatch(addTelegramRemoveStateData());
            dispatch(getTelegramTableData());
        } else if (addTelegram.loading === 'failed') {
            notify(addTelegram?.error?.data?.message, true);
            dispatch(addTelegramRemoveStateData());
        }
    }, [addTelegram.loading, dispatch]);

    const initialFormValues = { telegram_id: '' };

    const InviteUserSchema = Yup.object().shape({
        telegram_id: Yup.string().required('This Telegram ID field is required.'),
    });

    const handleAddTelegram = (value) => {
        const data = { telegram_id: value?.telegram_id };
        dispatch(addTelegramData(data));
    };

    const closeBtn = (
        <button className="close text-white" onClick={toggle} type="button">
            &times;
        </button>
    );

    const getFieldClass = (fieldName, errors, touched) =>
        errors[fieldName] && touched[fieldName] ? 'has-error' : '';

    return (
        <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} className="facility-modal position-relative">
            <Formik
                initialValues={initialFormValues}
                validationSchema={InviteUserSchema}
                onSubmit={(values) => handleAddTelegram(values)}
            >
                {({ errors, touched, handleSubmit, getFieldProps, values }) => (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                    >
                        <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
                            <p className="pr-2 mb-3 h4 text-white text-capitalize">Add New Telegram ID</p>
                        </ModalHeader>
                        <ModalBody className="facility-modal-body position-relative animation-transition-general">
                            <Row>
                                <Col sm={12}>
                                    <FormGroup className={getFieldClass('telegram_id', errors, touched)}>
                                        <Label for="telegram_id" className="mb-0">
                                            Telegram ID
                                        </Label>
                                        <Input
                                            id="telegram_id"
                                            name="telegram_id"
                                            type="text"
                                            className="bg-dark text-white"
                                            invalid={errors.telegram_id && touched.telegram_id}
                                            value={values.telegram_id}
                                            {...getFieldProps('telegram_id')}
                                        />
                                        <ErrorMessage name="telegram_id" component={FormFeedback} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className="facility-modal-footer position-sticky bottom-0">
                            <Button
                                className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize"
                                color="primary"
                                type="button"
                                onClick={toggle}
                            >
                                Close
                            </Button>
                            <Button
                                className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize"
                                color="primary"
                                type="submit"
                                disabled={addTelegram.loading === 'loading'}
                            >
                                {addTelegram.loading === 'loading' ? <Spinner size="sm">Loading...</Spinner> : 'Add'}
                            </Button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddTelegramId;
