import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { DELETE_DEVICE_PAIRINGS, DELETE_DEVICE } from "utils/utils";

export const deleteDevicePairingData = createAsyncThunk(
  "deleteDevicePairing/deleteDevicePairingData",
  async (data, { rejectWithValue }) => {
    const token = secureLocalStorage.getItem("chart-token");
    const config = {
      method: "delete",
      url: DELETE_DEVICE + data.id,
      headers: {
        Authorization: token,
      },
    };
    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  data: null,
  loading: "idle",
  error: null,
};

export const deleteDevicePairingSlice = createSlice({
  name: "deleteDevicePairing",
  initialState,
  reducers: {
    deleteDevicePairingsRemoveStateData: (state, action) => {
      state.loading = "idle";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDevicePairingData.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(deleteDevicePairingData.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload?.data;
        state.error = null;
      })
      .addCase(deleteDevicePairingData.rejected, (state, action) => {
        state.loading = "failed";
        state.data = null;
        state.error = action.payload;
      });
  },
});

export const { deleteDevicePairingsRemoveStateData } =
  deleteDevicePairingSlice.actions;
export default deleteDevicePairingSlice.reducer;
