import {
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dismissNotificationRemoveStateData } from "features/notification/dismissNotificationSlice";
import { dismissNotificationData } from "features/notification/dismissNotificationSlice";
import { getNotificationListData } from "features/notification/notificationListSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { dismissAllNotificationRemoveStateData } from "features/notification/dismissAllNotificationSlice";
import { dismissAllNotificationData } from "features/notification/dismissAllNotificationSlice";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";

const NotificationModel = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const notificationAlertRef = React.useRef(null);
  const notificationList = useSelector((state) => state.notificationList);
  const dismissNotification = useSelector((state) => state.dismissNotification);
  const dismissAllNotification = useSelector(
    (state) => state.dismissAllNotification
  );
  const closeBtn = () => (
    <button className="close text-white" onClick={toggle} type="button">
      &times;
    </button>
  );

  const notificationDismiss = async (item) => {
    await dispatch(dismissNotificationData({ id: item?.id }));
    dispatch(getNotificationListData());
  };
  const notificationDismissAll = async () => {
    await dispatch(dismissAllNotificationData());
    dispatch(getNotificationListData());
  };

  const notify = (msg, isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError ? "danger" : "success",
      icon: isError
        ? "tim-icons icon-alert-circle-exc"
        : "tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  React.useEffect(() => {
    if (dismissNotification.loading === "succeeded") {
      dispatch(getNotificationListData());
      dispatch(dismissNotificationRemoveStateData());
      if (notificationList?.List?.length === 1) {
        open && toggle();
      }
    } else if (dismissNotification.loading === "failed") {
      notify(dismissNotification?.error?.data?.message, true);
      dispatch(dismissNotificationRemoveStateData());
    }
  }, [dismissNotification.loading]);

  React.useEffect(() => {
    if (dismissAllNotification.loading === "succeeded") {
      dispatch(getNotificationListData());
      dispatch(dismissAllNotificationRemoveStateData());
      if (notificationList?.List?.length === 1) {
        open && toggle();
      }
    } else if (dismissAllNotification.loading === "failed") {
      notify(dismissAllNotification?.error?.data?.message, true);
      dispatch(dismissAllNotificationRemoveStateData());
    }
  }, [dismissAllNotification.loading]);

  React.useEffect(() => {
    open && dispatch(getNotificationListData());
  }, [open]);

  const gotoTelegram = () => {};

  return (
    <Modal
      returnFocusAfterClose={true}
      isOpen={open}
      backdrop={true}
      toggle={toggle}
      size="md"
      className="facility-modal mr-2 mt-0 mt-sm-5 rounded-lg overflow-hidden"
    >
      <ModalBody className="facility-modal-body position-relative rounded-lg ">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <div className="d-flex pb-3 mb-3 border-bottom border-white flex-row justify-content-between align-items-center text-white w-100">
          <Row className="w-100">
            <Col>
              <p className="pr-2 mb-0 h3 text-white text-capitalize fw-bold">
                Notifications
              </p>
            </Col>
            <Col>
              <div className="d-flex s3 w-100 mx-1 h-100 justify-content-end align-items-center text-left text-white">
                <div className="d-flex w-100 h-100 justify-content-end align-items-center text-left text-white">
                  <NavLink
                    className="btn badge-danger  btn-default border-white p-2 text-white text-capitalize h6 d-flex justify-content-center align-content-center"
                    type="button"
                    to={"/admin/telegram"}
                    onClick={toggle}
                  >
                    <FontAwesomeIcon
                      icon={faTelegram}
                      size="lg"
                      className="mx-1"
                      style={{ color: "#fff" }}
                    />{" "}
                    <p className="h6 mb-0 mt-1 text-white text-truncate w-100">
                      Telegram
                    </p>
                  </NavLink>
                </div>
                <div className="d-flex w-100 mx-1 h-100 justify-content-end align-items-center text-left text-white">
                  <button
                    className="btn btn-default border-white p-2 text-white text-capitalize h6 d-flex justify-content-center align-content-center"
                    color="danger"
                    type="button"
                    onClick={() => notificationDismissAll()}
                    disabled={dismissAllNotification.loading === "loading"}
                  >
                    {dismissAllNotification.loading === "loading" ? (
                      <>
                        <Spinner size="sm">Loading...</Spinner>Loading...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faXmark}
                          size="lg"
                          className="mx-1"
                          style={{ color: "#ffffff" }}
                        />{" "}
                        <p className="h6 mb-0 mt-1 text-white text-truncate w-100">
                          Dismiss All
                        </p>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </Col>
            {/* <Col>
            <div className="d-flex w-100 h-100 justify-content-start align-items-center text-left text-white">
              <Button className="btn-simple border-white p-1 px-2 text-white d-flex justify-content-start align-items-center text-capitalize" color="primary" type="button" >
                <i className="tim-icons icon-simple-add pr-2 mb-1" />   <p className='h6 mb-0  text-white text-truncate w-100'>Settings Alarm </p>
              </Button>
              </div>
            </Col> */}
          </Row>
          {closeBtn()}
        </div>
        <Row>
          {notificationList?.List?.length > 0 ? (
            notificationList?.List?.map((item) => {
              return (
                <Col sm={12} key={item}>
                  <Card className="rounded-lg mb-3">
                    <CardBody>
                      <div className="w-100">
                        <p className="h6 mb-1 text-white text-truncate w-100">
                          {dayjs(item?.created_at).format(
                            "DD MMM, YYYY  HH:mm"
                          )}
                        </p>
                      </div>
                      <div className="d-flex w-full h-full justify-content-between align-items-center text-left text-white">
                        {/* <i className="tim-icons icon-simple-add pr-2" /> */}
                        <div className="d-flex w-full justify-content-start align-items-center">
                          <div className="p-1  rounded-circle  mr-2">
                            {item?.type === "warning" ? (
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                size="lg"
                                className="mx-1"
                                style={{ color: "#d96330" }}
                              />
                            ) : (
                              <i className="tim-icons icon-alert-circle-exc  mx-1 text-danger" />
                            )}
                          </div>
                          <div className="text-left text-white d-flex justify-content-start align-items-center flex-column">
                            <h6 className="mb-1 text-white"> {item?.title}</h6>
                            <h5 className="mb-0 text-white">
                              {" "}
                              {item?.message}
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex w-fit h-full justify-content-end align-items-center text-left text-white">
                          <Button
                            className="btn-simple border-white p-1 px-2 text-white d-flex justify-content-between align-items-center text-capitalize"
                            color="primary"
                            type="button"
                            onClick={(e) => notificationDismiss(item)}
                            disabled={
                              dismissNotification.id === item?.id &&
                              dismissNotification.loading === "loading"
                            }
                          >
                            {dismissNotification.id === item?.id &&
                            dismissNotification.loading === "loading" ? (
                              <>
                                <Spinner size="sm">Loading...</Spinner>
                                Loading...
                              </>
                            ) : (
                              "Dismiss"
                            )}
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Col sm={12}>
              <Card className="rounded-lg mb-3">
                <CardBody>
                  <div className="d-flex w-full h-full justify-content-between align-items-center text-white">
                    <div className="text-white">
                      <h5 className="mb-0 text-white">
                        {" "}
                        There are no notifications
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default NotificationModel;
