import { configureStore } from '@reduxjs/toolkit'
import addAlarmSlice from 'features/alarm/addAlarmSlice';
import alarmTableSlice from 'features/alarm/alarmTableSlice';
import deleteAlarmSlice from 'features/alarm/deleteAlarmSlice';
import editAlarmSlice from 'features/alarm/editAlarmSlice';
import dashboardCardSlice from 'features/dashboard/dashboardCardSlice';
import dashboardChartSlice from 'features/dashboard/dashboardChartSlice';
import dashboardDataSlice from 'features/dashboard/dashboardDataSlice';
import addDevicePairingsSlice  from 'features/device/addDevicePairingSlice';
import addDeviceSlice from 'features/device/addDeviceSlice';
import deleteDevicePairingSlice from 'features/device/deleteDevicePairingSlice';
import deviceListSlice from 'features/device/deviceListSlice';
import devicePairingsSlice from 'features/device/devicePairingsSlice';
import deviceTableSlice from 'features/device/deviceTableSlice';
import editDeviceSlice from 'features/device/editDeviceSlice';
import serialSlice from 'features/device/serialSlice';
import addFacilitySlice from 'features/facility/addFacilitySlice';
import areaListSlice from 'features/facility/areaListSlice';
import deleteFacilitySlice from 'features/facility/deleteFacility';
import editFacilitySlice from 'features/facility/editFacilitySlice';
import facilityListSlice from 'features/facility/facilityListSlice';
import facilityTableSlice from 'features/facility/facilityTableSlice';
import dismissAllNotificationSlice from 'features/notification/dismissAllNotificationSlice';
import dismissNotificationSlice from 'features/notification/dismissNotificationSlice';
import notificationListSlice from 'features/notification/notificationListSlice';
import addProfileSlice from 'features/profile/addProfileSlice';
import deleteProfileSlice from 'features/profile/deleteProfileSlice';
import editProfileSlice from 'features/profile/editProfileSlice';
import profileListSlice from 'features/profile/profileListSlice';
import profileTableSlice from 'features/profile/profileTableSlice';
import addScheduleSlice from 'features/schedule/addScheduleSlice';
import deleteScheduleSlice from 'features/schedule/deleteScheduleSlice';
import editScheduleSlice from 'features/schedule/editScheduleSlice';
import scheduleTableSlice from 'features/schedule/scheduleTableSlice';
import addTelegramSlice from 'features/telegram/addTelegramSlice';
import deleteTelegramSlice from 'features/telegram/deleteTelegramSlice';
import telegramTableSlice from 'features/telegram/telegramTableSlice';
import userSlice from 'features/user/userSlice';

export const store = configureStore({
  reducer: {
    dashboardChart: dashboardChartSlice,
    dashboardCard: dashboardCardSlice,
    dashboardData: dashboardDataSlice,
    userData: userSlice,
    facilityTable: facilityTableSlice,
    addFacility:addFacilitySlice,
    deleteFacility:deleteFacilitySlice,
    editFacility:editFacilitySlice,
    facilityList:facilityListSlice,
    areaList:areaListSlice,
    serial:serialSlice,
    deviceTable:deviceTableSlice,
    addDevice:addDeviceSlice,
    editDevice:editDeviceSlice,
    deviceList:deviceListSlice,
    devicePairings:devicePairingsSlice,
    addDevicePairings:addDevicePairingsSlice,
    deleteDevicePairing:deleteDevicePairingSlice,
    profileTable:profileTableSlice,
    addProfile:addProfileSlice,
    editProfile:editProfileSlice,
    deleteProfile:deleteProfileSlice,
    profileList:profileListSlice,
    scheduleTable:scheduleTableSlice,
    addSchedule:addScheduleSlice,
    editSchedule:editScheduleSlice,
    deleteSchedule:deleteScheduleSlice,
    alarmTable:alarmTableSlice,
    addAlarm:addAlarmSlice,
    editAlarm:editAlarmSlice,
    deleteAlarm:deleteAlarmSlice,
    notificationList:notificationListSlice,
    dismissNotification:dismissNotificationSlice,
    dismissAllNotification:dismissAllNotificationSlice,
    telegramTable:telegramTableSlice,
    addTelegram:addTelegramSlice,
    deleteTelegram:deleteTelegramSlice,
  },
})