import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";

import { faCloudversify, faEnvira } from "@fortawesome/free-brands-svg-icons";
import {
  faArchway,
  faDroplet,
  faTemperatureThreeQuarters,
  faWarehouse,
  faWind,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { getDashboardData } from "features/dashboard/dashboardDataSlice";
import { getAreaListData } from "features/facility/areaListSlice";
import { getFacilityListData } from "features/facility/facilityListSlice";
import { getDeviceListData } from "features/device/deviceListSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const colors = ["red", "orange", "yellow", "green", "blue", "purple", "pink"];

const Dashboard = () => {
  const { chartData, loading, error } = useSelector(
    (state) => state.dashboardChart
  );
  const dashboardData = useSelector((state) => state.dashboardData);

  const cardData = useSelector((state) => state.dashboardCard);
  const facilityList = useSelector((state) => state.facilityList);
  const areaList = useSelector((state) => state.areaList);
  const deviceList = useSelector((state) => state.deviceList);
  const dispatch = useDispatch();
  const [bigChartData, setBigChartData] = React.useState("data1");
  const [chartOption, setChartOption] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const chart_1_2_3_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    interaction: {
      intersect: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: {
        barPercentage: 1.6,
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },

      xAxes: {
        barPercentage: 1.6,
        minWidth: 800,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    },
  };
  const setBgChartData = (name) => {
    setBigChartData(name);
  };
  const [datasets, setDatasets] = React.useState({});
  const [firstRowData, setFirstRowData] = React.useState({});
  const [facilityValue, setFacilityValue] = React.useState({});
  const [areaValue, setAreaValue] = React.useState({});
  const [deviceValue, setDeviceValue] = React.useState({});
  const mailLoading =
    facilityList?.loading === "loading" ||
    areaList?.loading === "loading" ||
    deviceList?.loading === "loading";
  const UpdateNow = () => {
    if (
      !mailLoading &&
      facilityValue?.value &&
      areaValue?.value &&
      deviceValue?.value
    ) {
      setLoader(true);
      dispatch(
        getDashboardData({
          facility_id: facilityValue?.value,
          cultivation_area_id: areaValue?.value,
          device_id: deviceValue?.value,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !mailLoading &&
      facilityValue?.value &&
      areaValue?.value &&
      deviceValue?.value
    ) {
      dispatch(
        getDashboardData({
          facility_id: facilityValue?.value,
          cultivation_area_id: areaValue?.value,
          device_id: deviceValue?.value,
        })
      );
    }
  }, [areaValue, deviceValue]);

  useEffect(() => {
    setInterval(() => {
      if (
        !mailLoading &&
        facilityValue?.value &&
        areaValue?.value &&
        deviceValue?.value
      ) {
        const location = window.location.href;
        if (location.includes("dashboard")) {
          dispatch(
            getDashboardData({
              facility_id: facilityValue?.value,
              cultivation_area_id: areaValue?.value,
              device_id: deviceValue?.value,
            })
          );
        }
      }
    }, 60000);
  }, []);

  useEffect(() => {
    if (dashboardData?.loading === "succeeded") {
      setDatasets({
        data1: (canvas) => {
          const data1Labels = dashboardData?.data?.day?.dayChartData?.map(
            (item) => dayjs(item?.hour, "H").format("HH:mm")
          );
          const avgTemperature = dashboardData?.data?.day?.dayChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_temperature)
          );
          const avgHumidity = dashboardData?.data?.day?.dayChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_humidity)
          );
          const avgCO2 = dashboardData?.data?.day?.dayChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_co2)
          );
          const avgVPD = dashboardData?.data?.day?.dayChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_vpd)
          );
          const avgLeafTemperature = dashboardData?.data?.day?.dayChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_leaf_temperature)
          );
          let ctx = canvas.getContext("2d");

          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

          gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
          gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
          gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

          return {
            labels: data1Labels,
            datasets: [
              {
                label: "Temperature",
                data: avgTemperature,
                borderColor: "rgba(255, 99, 132, 1)", // Red color for the line
                backgroundColor: "rgba(255, 99, 132, 1)",
                yAxisID: "y",
              },
              {
                label: "Humidity",
                data: avgHumidity,
                borderColor: "rgba(54, 162, 235, 1)", // Blue color for the line
                backgroundColor: "rgba(54, 162, 235, 1)",
                yAxisID: "y1",
              },
              {
                label: "CO2",
                data: avgCO2,
                borderColor: "rgba(75, 192, 192, 1)", // Teal color for the line
                backgroundColor: "rgba(75, 192, 192, 1)",
                yAxisID: "y2",
              },
              {
                label: "VPD",
                data: avgVPD,
                borderColor: "rgba(153, 102, 255, 1)", // Purple color for the line
                backgroundColor: "rgba(153, 102, 255, 1)",
                yAxisID: "y2",
              },
              {
                label: "Leaf Temperature",
                data: avgLeafTemperature,
                borderColor: "rgba(255, 159, 64, 1)", // Orange color for the line
                backgroundColor: "rgba(255, 159, 64, 1)",
                yAxisID: "y",
              },
            ],
          };
        },

        data2: (canvas) => {
          const data2Labels = dashboardData?.data?.week?.weekChartData?.map(
            (item) => item?.date
          );
          const avgTemperature = dashboardData?.data?.week?.weekChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_temperature)
          );
          const avgHumidity = dashboardData?.data?.week?.weekChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_humidity)
          );
          const avgCO2 = dashboardData?.data?.week?.weekChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_co2)
          );
          const avgVPD = dashboardData?.data?.week?.weekChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_vpd)
          );
          const avgLeafTemperature =
            dashboardData?.data?.week?.weekChartData.map((dataPoint) =>
              parseFloat(dataPoint.avg_leaf_temperature)
            );
          let ctx = canvas.getContext("2d");

          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

          gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
          gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
          gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

          return {
            labels: data2Labels,
            datasets: [
              {
                label: "Temperature",
                data: avgTemperature,
                borderColor: "rgba(255, 99, 132, 1)", // Red color for the line
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                yAxisID: "y",
              },
              {
                label: "Humidity",
                data: avgHumidity,
                borderColor: "rgba(54, 162, 235, 1)", // Blue color for the line
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                yAxisID: "y1",
              },
              {
                label: "CO2",
                data: avgCO2,
                borderColor: "rgba(75, 192, 192, 1)", // Teal color for the line
                backgroundColor: "rgba(75, 192, 192, 0.5)",
                yAxisID: "y2",
              },
              {
                label: "VPD",
                data: avgVPD,
                borderColor: "rgba(153, 102, 255, 1)", // Purple color for the line
                backgroundColor: "rgba(153, 102, 255, 0.5)",
                yAxisID: "y2",
              },
              {
                label: "Leaf Temperature",
                data: avgLeafTemperature,
                borderColor: "rgba(255, 159, 64, 1)", // Orange color for the line
                backgroundColor: "rgba(255, 159, 64, 0.5)",
                yAxisID: "y",
              },
            ],
          };
        },
        data3: (canvas) => {
          const data2Labels = dashboardData?.data?.month?.monthChartData?.map(
            (item) => item?.date
          );
          const avgTemperature = dashboardData?.data?.month?.monthChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_temperature)
          );
          const avgHumidity = dashboardData?.data?.month?.monthChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_humidity)
          );
          const avgCO2 = dashboardData?.data?.month?.monthChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_co2)
          );
          const avgVPD = dashboardData?.data?.month?.monthChartData.map(
            (dataPoint) => parseFloat(dataPoint.avg_vpd)
          );
          const avgLeafTemperature =
            dashboardData?.data?.month?.monthChartData.map((dataPoint) =>
              parseFloat(dataPoint.avg_leaf_temperature)
            );
          let ctx = canvas.getContext("2d");

          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

          gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
          gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
          gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

          return {
            labels: data2Labels,
            datasets: [
              {
                label: "Temperature",
                data: avgTemperature,
                borderColor: "rgba(255, 99, 132, 1)", // Red color for the line
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                yAxisID: "y",
              },
              {
                label: "Humidity",
                data: avgHumidity,
                borderColor: "rgba(54, 162, 235, 1)", // Blue color for the line
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                yAxisID: "y1",
              },
              {
                label: "CO2",
                data: avgCO2,
                borderColor: "rgba(75, 192, 192, 1)", // Teal color for the line
                backgroundColor: "rgba(75, 192, 192, 0.5)",
                yAxisID: "y2",
              },
              {
                label: "VPD",
                data: avgVPD,
                borderColor: "rgba(153, 102, 255, 1)", // Purple color for the line
                backgroundColor: "rgba(153, 102, 255, 0.5)",
                yAxisID: "y2",
              },
              {
                label: "Leaf Temperature",
                data: avgLeafTemperature,
                borderColor: "rgba(255, 159, 64, 1)", // Orange color for the line
                backgroundColor: "rgba(255, 159, 64, 0.5)",
                yAxisID: "y",
              },
            ],
          };
        },
      });
      setLoader(false);
    }
    if (loading === "failed") {
      console.log(dashboardData?.error);
      setLoader(false);
    }
  }, [dashboardData?.loading]);

  React.useEffect(() => {
    dispatch(getFacilityListData({}));
  }, []);

  React.useEffect(() => {
    if (facilityList.loading === "succeeded") {
      setFacilityValue({
        value: facilityList.List[0]?.id,
        label: facilityList.List[0]?.facility_name,
      });
      dispatch(getAreaListData({ id: facilityList.List[0]?.id }));
      dispatch(getDeviceListData({ facility_id: facilityList.List[0]?.id }));
    }
  }, [facilityList.loading]);

  useEffect(() => {
    if (facilityValue?.value) {
      dispatch(getAreaListData({ id: facilityValue?.value }));
      dispatch(getDeviceListData({ facility_id: facilityValue?.value }));
    }
  }, [facilityValue]);

  React.useEffect(() => {
    if (areaList.loading === "succeeded") {
      setAreaValue({
        value: areaList.List[0]?.id,
        label: areaList.List[0]?.cultivation_name,
      });
    }
  }, [areaList.loading]);

  React.useEffect(() => {
    if (deviceList.loading === "succeeded") {
      setDeviceValue({
        value: deviceList.List[0]?.id,
        label: deviceList.List[0]?.device_name,
      });
    }
  }, [deviceList.loading]);

  const dataForCard = (key) => {
    // if (bigChartData === "data1") {
    return parseFloat(dashboardData?.data?.day?.avg?.[key])?.toFixed(2);
    // }
    // else if (bigChartData === "data2") {
    //   return parseFloat(dashboardData?.data?.week?.avg?.[key])?.toFixed(2);
    // }
    // else if (bigChartData === "data3") {
    //   return parseFloat(dashboardData?.data?.month?.avg?.[key])?.toFixed(2);
    // }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <div className="d-flex flex-wrap gap-2 w-100 p-2 border-bottom">
              <div className="d-flex w-fit p-1 mr-3">
                <div className="d-flex justify-content-start align-items-center w-fit">
                  <FontAwesomeIcon
                    icon={faWarehouse}
                    size="xs"
                    className="mb-1"
                    style={{ color: "#ffffff" }}
                  />
                  <p className=" px-2 mb-0 text-white fs-2 font-weight-normal">
                    {" "}
                    Facility:
                  </p>
                </div>
                <Select
                  id="facility"
                  name="facility"
                  type="select"
                  className="bg-dark basic-single bg-transparent"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={facilityValue}
                  options={[
                    ...facilityList.List?.map((facility) => ({
                      value: facility?.id,
                      label: facility?.facility_name,
                    })),
                  ]}
                  onChange={(value) => {
                    setFacilityValue(value);
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                    }),
                  }}
                />
              </div>
              <div className="d-flex w-fit p-1 mr-3">
                <div className="d-flex justify-content-start align-items-center w-fit ">
                  <FontAwesomeIcon
                    icon={faArchway}
                    size="sm"
                    className="mb-1"
                    style={{ color: "#ffffff" }}
                  />
                  <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">
                    {" "}
                    Area:
                  </p>
                </div>
                <Select
                  id="area"
                  name="area"
                  type="select"
                  className="bg-dark basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={areaValue}
                  options={[
                    ...areaList.List?.map((area) => ({
                      value: area?.id,
                      label: area?.cultivation_name,
                    })),
                  ]}
                  onChange={(e) => {
                    setAreaValue(e);
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                    }),
                  }}
                />
              </div>
              <div className="d-flex w-fit p-1 mr-3">
                <div className="d-flex justify-content-start align-items-center w-fit ">
                  <FontAwesomeIcon
                    icon={faPlug}
                    size="sm"
                    className="mb-1"
                    style={{ color: "#ffffff" }}
                  />
                  <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">
                    {" "}
                    Device:
                  </p>
                </div>
                <Select
                  id="device"
                  name="device"
                  type="select"
                  className="bg-dark basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={deviceValue}
                  options={[
                    ...deviceList.List?.map((device) => ({
                      value: device?.id,
                      label: device?.device_name,
                    })),
                  ]}
                  onChange={(e) => {
                    setDeviceValue(e);
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                    }),
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs="12">
            {/* <div className="d-flex flex-wrap gap-2 w-100 p-2">
              <div className="d-flex flex-column w-fit p-1 mr-3">
                <p className=" px-2 mb-0 text-white fs-2 font-weight-normal"> Climate Overview</p>
                <p className=" px-2 mb-0 text-white fs-2 font-weight-normal"> {dayjs().format("MMMM, D, YYYY")}</p>
              </div>
            </div> */}
            <Row>
              <Col className="text-left mt-2 " sm="12" md="6" lg="8" xl="9">
                <CardTitle tag="h3" className="mb-1">
                  Climate Overview
                </CardTitle>
                <CardTitle tag="h5">
                  {bigChartData === "data1"
                    ? dayjs().format("MMMM, D, YYYY")
                    : bigChartData === "data2"
                    ? dayjs().format("MMMM, D") +
                      " - " +
                      dayjs().add(7, "day").format("D, YYYY")
                    : dayjs().format("MMMM, YYYY")}
                </CardTitle>
              </Col>
              {/* <Col sm="12" md="6" lg="4" xl="3" className="mt-2 text-right">
                <ButtonGroup
                  className="btn-group-toggle float-right w-100 mb-2"
                  data-toggle="buttons"
                >
                  <Button
                    color="info"
                    id="0"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data1",
                    })}
                    onClick={() => setBgChartData("data1")}
                  >
                    <span>
                      Day
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data2",
                    })}
                    onClick={() => setBgChartData("data2")}
                  >
                    <span>
                      Week
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data3",
                    })}
                    onClick={() => setBgChartData("data3")}
                  >
                    <span >
                      Month
                    </span>
                  </Button>
                </ButtonGroup>
              </Col> */}
            </Row>
          </Col>
          <Col xs="12">
            <Card className="card-chart dashboard-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Historical Data</h5>
                    {/* <CardTitle tag="h2">Climatic Conditions</CardTitle> */}
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        onClick={() => setBgChartData("data1")}
                      >
                        <span>Day</span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span>Week</span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span>Month</span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="overflow-auto">
                <div className="chart-area">
                  {Object.keys(datasets).length > 0 ? (
                    <Line
                      data={datasets[bigChartData]}
                      options={chart_1_2_3_options}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card className="card-stats dashboard-card">
              <CardBody>
                <div className="d-flex w-100 p-1 flex-row justify-content-between align-items-center text-nowrap ">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="info-icon text-center icon-success d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faTemperatureThreeQuarters}
                        size="2xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column text-nowrap ">
                    <div className="numbers text-nowrap ">
                      <p className="card-category ">Temperature</p>
                      <CardTitle tag="h3">
                        {dataForCard("avg_temperature")}
                      </CardTitle>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={UpdateNow}>
                  <i className="tim-icons icon-refresh-01" /> Update Now
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats dashboard-card">
              <CardBody>
                <div className="d-flex w-100 p-1 flex-row justify-content-between align-items-center text-nowrap ">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="info-icon text-center icon-primary d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faDroplet}
                        size="2xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column text-nowrap ">
                    <div className="numbers text-nowrap ">
                      <p className="card-category">Humidity</p>
                      <CardTitle tag="h3">
                        {dataForCard("avg_humidity")}
                      </CardTitle>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={UpdateNow}>
                  <i className="tim-icons icon-refresh-01" /> Update Now
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats dashboard-card">
              <CardBody>
                <div className="d-flex w-100 p-1 flex-row justify-content-between align-items-center text-nowrap ">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="info-icon text-center icon-danger d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faWind}
                        size="2xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column text-nowrap ">
                    <div className="numbers text-nowrap ">
                      <p className="card-category">
                        <>
                          {" "}
                          Co<sub>2</sub>
                        </>
                      </p>
                      <CardTitle tag="h3">{dataForCard("avg_co2")}</CardTitle>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={UpdateNow}>
                  <i className="tim-icons icon-refresh-01" /> Update Now
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats dashboard-card">
              <CardBody>
                <div className="d-flex w-100 p-1 flex-row justify-content-between align-items-center text-nowrap ">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="info-icon text-center icon-warning d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faCloudversify}
                        size="2xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column text-nowrap ">
                    <div className="numbers text-nowrap ">
                      <p className="card-category">VPD</p>
                      <CardTitle tag="h3">{dataForCard("avg_vpd")}</CardTitle>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={UpdateNow}>
                  <i className="tim-icons icon-refresh-01" /> Update Now
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-stats dashboard-card">
              <CardBody>
                <div className="d-flex w-100 p-1 flex-row justify-content-between align-items-center text-nowrap ">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="info-icon text-center icon-success d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faEnvira}
                        rotation={90}
                        size="2xl"
                        style={{ color: "#ffffff" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column text-nowrap ">
                    <div className="numbers text-nowrap ">
                      <p className="card-category"> Leaf Temperature</p>
                      <CardTitle tag="h3">
                        {dataForCard("avg_leaf_temperature")}
                      </CardTitle>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats cursor-pointer" onClick={UpdateNow}>
                  <i className="tim-icons icon-refresh-01" /> Update Now
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
      {!!loader && (
        <div className="loading-container">
          <div className="d-flex justify-content-center loading-div">
            <Spinner size="xl" />
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
