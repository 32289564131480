import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { DELETE_TELEGRAM } from 'utils/utils';

export const deleteTelegramData = createAsyncThunk(
  'deleteTelegram/deleteTelegramData',
  async (data, { rejectWithValue }) => {

    const token = secureLocalStorage.getItem("chart-token");
    const config = {
      method: "delete",
      url: DELETE_TELEGRAM + data.id,
      headers: {
        Authorization: token,
      },
    };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

const initialState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const deleteTelegramSlice = createSlice({
  name: 'deleteTelegram',
  initialState,
  reducers: {
    deleteTelegramRemoveStateData: (state, action) => {
      state.loading = 'idle'
      state.data = null
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteTelegramData.pending, (state, action) => {
        state.loading = 'loading'
      })
      .addCase(deleteTelegramData.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.data = action.payload?.data
        state.error = null
      })
      .addCase(deleteTelegramData.rejected, (state, action) => {
        state.loading = 'failed'
        state.data = null
        state.error = action.payload
      })
  },
})


export const { deleteTelegramRemoveStateData } = deleteTelegramSlice.actions
export default deleteTelegramSlice.reducer;